import React, { useEffect, useState, useCallback } from 'react';
import { fetchSummary } from '../action/balance';
import styled from 'styled-components';
import Container from '../components/style/Container';
import Screen from '../components/style/Screen';
import { withRouter } from 'react-router-dom';
const HelloIcon = require('../assets/hello.png');

const LineItemHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  border-bottom: 1px solid #e6e6e6;
  background-color: #e6e6e6;
  font-weight: bold;
`;

const Item = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flexdirection: column;
  border-bottom: 1px solid #e6e6e6;
  padding-top: 5px;
  padding-bottom: 5px;
  @media only screen and (max-width: 600px) {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.25em;
  }
`;

const Description = styled.div`
  margin: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

function SampleScreen({ history }) {
  const [ready, setReady] = useState(false);
  const [summary, setSummary] = useState({
    welcomed: false,
    debits: [],
    credits: [],
    summary: {}
  });
  const [view, setView] = useState('biweekly');

  const fetchData = useCallback(() => {
    fetchSummary()
      .then(response => {
        setReady(true);
        setSummary({
          welcomed: response.welcomed,
          debits: response.debits,
          credits: response.credits,
          summary: response.summary
        });
      })
      .catch(() => {
        // todo handle error
        setReady(true);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getAmountForView = item => {
    if (view === 'biweekly') {
      return item.biWeeklyAmount;
    } else if (view === 'monthly') {
      return item.monthAmount;
    }
  };

  const getSummaryForView = summary => {
    if (view === 'biweekly') {
      return (
        <>
          <LineHeader item='Bi-Weekly Summary' />;
          {!summary ? null : (
            <>
              <SummaryLineItem item='Debits' amount={summary?.totalBiWeeklyDebit} />
              <SummaryLineItem item='Credits' amount={summary?.totalBiWeeklyCredit} />
              {summary?.totalBiWeeklyCredit - summary?.totalBiWeeklyDebit < 0 ? (
                <SummaryLineItem
                  item='Deficit'
                  amount={(summary?.totalBiWeeklyCredit - summary?.totalBiWeeklyDebit).toFixed(2)}
                />
              ) : (
                <SummaryLineItem
                  item='Surplus'
                  amount={(summary?.totalBiWeeklyCredit - summary?.totalBiWeeklyDebit).toFixed(2)}
                />
              )}
              <br />
            </>
          )}
        </>
      );
    } else if (view === 'monthly') {
      return (
        <>
          <LineHeader item='Monthly Summary' />
          {!summary ? null : (
            <>
              <SummaryLineItem item='Debits' amount={summary?.totalMonthlyDebit} />
              <SummaryLineItem item='Credits' amount={summary?.totalMonthlyCredit} />
              {summary?.totalMonthlyCredit - summary?.totalMonthlyDebit < 0 ? (
                <SummaryLineItem
                  item='Deficit'
                  amount={(summary?.totalMonthlyCredit - summary?.totalMonthlyDebit).toFixed(2)}
                />
              ) : (
                <SummaryLineItem
                  item='Surplus'
                  amount={(summary?.totalMonthlyCredit - summary?.totalMonthlyDebit).toFixed(2)}
                />
              )}
            </>
          )}
        </>
      );
    }
  };

  const getWelcomeView = () => {
    return (
      <div style={{ width: '100%' }}>
        {/* <img src={HelloIcon} width={50} height={50} /> */}
        <div>
          <Description style={{ fontSize: '1.25em', marginTop: 0 }}>
            👋, this screen will list a summary for you normalized to bi-weekly or monthly values:
          </Description>
          <Description>All your bills will be listed here 👇</Description>
          <LineHeader item='All Debits' />
          <LineItem item='Mortgage' amount={800.02} />
          <LineItem item='Cable Bill' amount={120.95} />
          <Description>All your paycheques and windfalls will be listed here 👇</Description>
          <LineHeader item='All Credits' />
          <LineItem item='Paycheque' amount={2150.12} />
          <Description>And a summary of all your debits and credits will be here 👇</Description>
          <LineHeader item='Summary' />
          <SummaryLineItem item='Total Debits' amount='800.02' />
          <SummaryLineItem item='Total Credits' amount='2150.12' />
          <Description>Including either a Deficit or a Surplus based on the above information 👇</Description>
          <SummaryLineItem item='Surplus' amount='1350.10' />
          <Description>
            👉<button onClick={() => history.push('/items/')}>Start Creating Debits and Credits to get Started</button>
            👈
          </Description>
        </div>
      </div>
    );
  };

  const getSummary = () => {
    return (
      <>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', marginBottom: 5 }}>
          <select value={view} onChange={event => setView(event.target.value)}>
            <option value='biweekly'>Bi-Weekly</option>
            <option value='monthly'>Monthly</option>
          </select>
        </div>
        <LineHeader item='All Debits' />
        {summary?.debits?.map(lineItem => {
          const amount = getAmountForView(lineItem);
          return (
            <LineItem
              key={lineItem.id}
              item={lineItem.item}
              amount={amount}
              frequency={lineItem.frequency}
              cadence={lineItem.cadence}
              type={lineItem.type}
            />
          );
        })}
        <br />
        <LineHeader item='All Credits' />
        {summary?.credits?.map(lineItem => {
          const amount = getAmountForView(lineItem);
          return (
            <LineItem
              key={lineItem.id}
              item={lineItem.item}
              amount={amount}
              frequency={lineItem.frequency}
              cadence={lineItem.cadence}
              type={lineItem.type}
            />
          );
        })}
        <br />
        {getSummaryForView(summary?.summary)}
      </>
    );
  };

  return (
    <Container>
      <Screen>{ready ? !summary.welcomed ? getWelcomeView() : getSummary() : <div>loading</div>}</Screen>
    </Container>
  );
}

function LineItem({ item, amount, cadence, frequency, type }) {
  return (
    <Item>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'row', padding: 2 }}>
        <span style={{ textAlign: 'left', flex: 2 }}>{item}</span>
        <span style={{ textAlign: 'right', flex: 1 }}>{`$ ${amount.toFixed(2)}`}</span>
      </div>
    </Item>
  );
}

function SummaryLineItem({ item, amount }) {
  return (
    <Item>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'row', padding: 2 }}>
        <span style={{ textAlign: 'left', flex: 1 }}>{item}</span>
        <span style={{ textAlign: 'right', flex: 1 }}>{`$ ${amount}`}</span>
      </div>
    </Item>
  );
}

function LineHeader({ item }) {
  return (
    <LineItemHeader>
      <div style={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
        <span style={{ textAlign: 'left', flex: 1 }}>{item}</span>
      </div>
    </LineItemHeader>
  );
}

export default withRouter(SampleScreen);
