import React, { useEffect, useCallback, useState } from 'react';
import { fetchLineItems } from '../action/balance';
import Screen from '../components/style/Screen';
import Container from '../components/style/Container';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const SelectableItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f8f8f8;
  border: 1px solid transparent;
  border-bottom: 1px solid #e6e6e6;
  padding-top: 5px;
  padding-bottom: 5px;
  &:hover {
    background-color: #f1f1f1;
    border: 1px dashed #c1c1c1;
    cursor: pointer;
  }
  @media only screen and (max-width: 600px) {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.5em;
    &:hover {
      background-color: #f8f8f8;
      border: 1px solid transparent;
      border-bottom: 1px solid #e6e6e6;
    }
  }
`;

const LineItemHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  border-bottom: 1px solid #e6e6e6;
  background-color: #e6e6e6;
  font-weight: bold;
`;

function LineItems({ history }) {
  const [debits, setDebits] = useState([]);
  const [credits, setCredits] = useState([]);
  const dispatch = useDispatch();

  const fetchItems = useCallback(() => {
    // indicate where we are
    fetchLineItems().then(({ debits, credits }) => {
      setDebits(debits);
      setCredits(credits);
    });
  }, []);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <Container>
      <Screen>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', marginBottom: 5 }}>
          <button onClick={() => history.push('/items/new')}>Create New</button>
        </div>
        <LineHeader item='All Debits' />
        {debits?.map((lineItem) => {
          return (
            <LineItem key={lineItem.id} item={lineItem.item} onClick={() => history.push(`/items/${lineItem.id}`)} />
          );
        })}
        <br />
        <LineHeader item='All Credits' />
        {credits?.map((lineItem) => {
          return (
            <LineItem key={lineItem.id} item={lineItem.item} onClick={() => history.push(`/items/${lineItem.id}`)} />
          );
        })}
      </Screen>
    </Container>
  );
}

function LineItem({ item, onClick }) {
  return (
    <SelectableItem onClick={onClick}>
      <span>{item}</span>
    </SelectableItem>
  );
}

function LineHeader({ item }) {
  return (
    <LineItemHeader>
      <div style={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
        <span style={{ textAlign: 'left', flex: 1 }}>{item}</span>
      </div>
    </LineItemHeader>
  );
}

export default withRouter(LineItems);
