import React, { useEffect } from 'react';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import { isMobile } from 'react-device-detect';

var uiConfig = {
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.GithubAuthProvider.PROVIDER_ID],
  signInFlow: isMobile ? 'redirect' : 'popup'
};

export default function LoginProviders() {
  useEffect(() => {
    var ui = firebaseui.auth.AuthUI.getInstance();
    ui.start('#firebaseui-auth-container', {
      ...uiConfig,
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          console.log('Auth Success');
          return false;
        }
      }
    });
  }, []);

  return <div id='firebaseui-auth-container' />;
}
