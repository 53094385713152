import firebase from 'firebase/app';
import 'firebase/database';

export function deleteLineItem(id) {
  return new Promise((resolve, reject) => {
    const userId = firebase.auth().currentUser.uid;
    firebase
      .database()
      .ref(`/users/${userId}/lineitems/${id}`)
      .remove()
      .then(() => resolve())
      .catch(() => reject());
  });
}

export function createLineItem({ item, amount, cadence, frequency, type }) {
  return new Promise((resolve, reject) => {
    item = item?.trim();
    cadence = cadence?.trim();
    type = type?.trim();

    // validation
    let bOK = true;
    bOK = bOK && item.length > 0 && item.length < 128;
    bOK = bOK && !isNaN(amount) && Number(amount) >= 0;
    bOK = bOK && !isNaN(frequency) && Number(frequency) > 0;
    bOK = bOK && ['weeks', 'months', 'years'].includes(cadence);
    bOK = bOK && ['debit', 'credit'].includes(type);

    if (!bOK) {
      console.log('REJECTING');
      return reject();
    }

    const userId = firebase.auth().currentUser.uid;
    var newId = firebase.database().ref(`/users/${userId}/lineitems/`).push();

    newId
      .set({
        item: item,
        amount: amount,
        cadence: cadence,
        frequency: frequency,
        type: type,
      })
      .then(() => resolve())
      .catch(() => reject());
  });
}

export function updateLineItem({ id, item, amount, cadence, frequency, type }) {
  return new Promise((resolve, reject) => {
    item = item?.trim();
    cadence = cadence?.trim();
    type = type?.trim();

    // validation
    let bOK = true;
    bOK = bOK && item?.length > 0 && item.length < 128;
    bOK = bOK && !isNaN(amount) && Number(amount) >= 0;
    bOK = bOK && !isNaN(frequency) && Number(frequency) > 0;
    bOK = bOK && ['weeks', 'months', 'years'].includes(cadence);
    bOK = bOK && ['debit', 'credit'].includes(type);

    if (!bOK) {
      console.log({
        item: item,
        amount: amount,
        cadence: cadence,
        frequency: frequency,
        type: type,
      });
      return reject();
    }

    const userId = firebase.auth().currentUser.uid;
    firebase
      .database()
      .ref(`/users/${userId}/lineitems/${id}/`)
      .update({
        item: item,
        amount: amount,
        cadence: cadence,
        frequency: frequency,
        type: type,
      })
      .then(() => resolve())
      .catch(() => reject());
  });
}

export function fetchLineItem(itemId) {
  return new Promise((resolve, reject) => {
    const userId = firebase.auth().currentUser.uid;
    firebase
      .database()
      .ref(`/users/${userId}/lineitems/${itemId}/`)
      .once('value', (snapshot) => {
        if (snapshot.exists()) {
          const item = snapshot.val();
          resolve({
            id: itemId,
            amount: item.amount,
            item: item.item,
            frequency: item.frequency,
            cadence: item.cadence,
            startDate: item.startDate,
            endDate: item.endDate,
            type: item.type,
          });
        } else {
          reject();
        }
      })
      .catch(() => reject());
  });
}

export function fetchLineItems() {
  return new Promise((resolve, reject) => {
    const userId = firebase.auth().currentUser.uid;
    firebase
      .database()
      .ref(`/users/${userId}/lineitems/`)
      .once('value', (snapshot) => {
        if (snapshot.exists()) {
          let debits = [];
          let credits = [];
          const items = snapshot.val();
          for (var id in items) {
            if (items[id].type === 'debit') {
              debits.push({
                id: id,
                amount: items[id].amount,
                item: items[id].item,
                frequency: items[id].frequency,
                cadence: items[id].cadence,
                startDate: items[id].startDate,
                endDate: items[id].endDate,
                type: items[id].type,
              });
            } else {
              credits.push({
                id: id,
                amount: items[id].amount,
                item: items[id].item,
                frequency: items[id].frequency,
                cadence: items[id].cadence,
                startDate: items[id].startDate,
                endDate: items[id].endDate,
                type: items[id].type,
              });
            }
          }
          resolve({ debits: debits.sort(_sortItems), credits: credits.sort(_sortItems) });
        } else {
          reject();
        }
      })
      .catch(() => reject());
  });
}

export function fetchSummary() {
  return new Promise((resolve, reject) => {
    const userId = firebase.auth().currentUser.uid;
    firebase
      .database()
      .ref(`/users/${userId}/lineitems/`)
      .once('value', (snapshot) => {
        if (snapshot.exists()) {
          let debits = [];
          let credits = [];
          const items = snapshot.val();
          let totalMonthlyDebit = 0;
          let totalMonthlyCredit = 0;
          for (var id in items) {
            const amount = items[id].amount;
            const monthAmount = _getMonthlyAmount(
              items[id].item,
              items[id].amount,
              items[id].cadence,
              items[id].frequency
            );
            const biWeeklyAmount = (monthAmount * 12) / 26;
            if (items[id].type === 'debit') {
              debits.push({
                id: id,
                amount: amount,
                monthAmount: monthAmount,
                biWeeklyAmount: biWeeklyAmount,
                item: items[id].item,
              });
              totalMonthlyDebit = totalMonthlyDebit + monthAmount;
            } else {
              credits.push({
                id: id,
                amount: amount,
                monthAmount: monthAmount,
                biWeeklyAmount: biWeeklyAmount,
                item: items[id].item,
              });

              totalMonthlyCredit = totalMonthlyCredit + monthAmount;
            }
          }

          // if all values are empty, let's inform the user they need to be welcomed
          let welcomed = credits?.length > 0 || debits?.length > 0;

          // calculate some values
          const summary = {
            totalMonthlyDebit: totalMonthlyDebit.toFixed(2),
            totalMonthlyCredit: totalMonthlyCredit.toFixed(2),
            totalBiWeeklyDebit: ((totalMonthlyDebit * 12) / 26).toFixed(2),
            totalBiWeeklyCredit: ((totalMonthlyCredit * 12) / 26).toFixed(2),
          };

          resolve({
            welcomed: welcomed,
            summary: summary,
            debits: debits.sort(_sortItems),
            credits: credits.sort(_sortItems),
          });
        } else {
          reject();
        }
      })
      .catch(() => reject());
  });
}

function _getMonthlyAmount(item, amount, cadence, frequency) {
  if (cadence === 'months') {
    return amount / frequency;
  }

  if (cadence === 'weeks') {
    return ((amount / frequency) * 52) / 12;
  }

  if (cadence === 'years') {
    return amount / 12 / frequency;
  }
}

function _sortItems(a, b) {
  // primary sort on position
  if (a.biWeeklyAmount > b.biWeeklyAmount) {
    return -1;
  } else if (a.biWeeklyAmount < b.biWeeklyAmount) {
    return 1;
  }
}
