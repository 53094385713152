import React, { useEffect, useCallback, useState } from 'react';
import { fetchLineItem, updateLineItem, createLineItem, deleteLineItem } from '../action/balance';
import Screen from '../components/style/Screen';
import Container from '../components/style/Container';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

const InputLabel = styled.span`
  width: 100%;
  text-align: left;
  font-size: 1em;
  margin-bottom: 5px;
  margin-top: 10px;
  font-weight: bold;
`;

const FormContainer = styled.div`
  width: 100%;
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 5px 10px;
  border-radius: 5px;
  font: inherit;
  @media only screen and (min-width: 600px) {
    border: none;
    box-shadow: inset 0 0 0 1px #c3c3c3;
    outline: none;
  }
`;

const Select = styled.select`
  padding: 5px 10px;
  border-radius: 5px;
  font: inherit;
  @media only screen and (min-width: 600px) {
    border: none;
    outline: none;
    box-shadow: inset 0 0 0 1px #c3c3c3;
  }
`;

function LineItem({ location, history }) {
  const [ready, setReady] = useState(false);
  const [create, setCreate] = useState(true);
  const [item, setItem] = useState({
    item: '',
    amount: 0,
    frequency: 0,
    cadence: 'weeks',
    type: 'debit',
  });

  const fetchItem = useCallback((id) => {
    setCreate(false);
    fetchLineItem(id).then((lineItem) => {
      setItem(lineItem);
      setReady(true);
    });
  }, []);

  useEffect(() => {
    const id = location.pathname.split('/')[2];
    if (id === 'new') {
      setReady(true);
    } else {
      fetchItem(location.pathname.split('/')[2]);
    }
  }, [fetchItem]);

  const updateItem = (key, value) => {
    setItem((prev) => ({ ...prev, [key]: value }));
  };

  const saveItem = () => {
    if (create) {
      createLineItem(item)
        .then(() => {
          history.push('/items/');
        })
        .catch(() => {
          console.log('Error creating');
        });
    } else {
      updateLineItem(item)
        .then(() => {
          history.push('/items/');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteItem = () => {
    if (!create) {
      deleteLineItem(item.id)
        .then(() => {
          history.push('/items/');
        })
        .catch(() => {
          console.log('Error deleting');
        });
    }
  };

  return (
    <Container>
      <Screen>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'flex-start',
            marginBottom: 0,
          }}
        >
          <div style={{ margin: 0, flex: 1, textAlign: 'left' }}>
            <button onClick={() => history.push('/items/')}>Back</button>
          </div>
          <div style={{ margin: 0 }}>
            <button onClick={saveItem}>Save</button>
          </div>
        </div>
        <FormContainer>
          <InputLabel>Line Item</InputLabel>
          <Input value={item?.item} onChange={(event) => updateItem('item', event.target.value)} disabled={!ready} />
          <InputLabel>Amount</InputLabel>
          <Input
            value={item?.amount}
            type='number'
            onChange={(event) => updateItem('amount', event.target.value)}
            disabled={!ready}
          />
          <InputLabel>Frequency</InputLabel>
          <Input
            value={item?.frequency}
            type='number'
            onChange={(event) => updateItem('frequency', event.target.value)}
            disabled={!ready}
          />
          <InputLabel>Cadence</InputLabel>

          <Select
            value={item?.cadence}
            onChange={(event) => updateItem('cadence', event.target.value)}
            disabled={!ready}
          >
            {ready ? (
              <>
                <option value='weeks'>Weeks</option>
                <option value='months'>Months</option>
                <option value='years'>Years</option>
              </>
            ) : null}
          </Select>

          <InputLabel>Type</InputLabel>
          <Select value={item?.type} onChange={(event) => updateItem('type', event.target.value)} disabled={!ready}>
            {ready ? (
              <>
                <option value='debit'>Debit</option>
                <option value='credit'>Credit</option>
              </>
            ) : null}
          </Select>
        </FormContainer>
        {create ? null : (
          <>
            <hr style={{ width: '100%' }} />
            <div
              style={{
                width: '100%',
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                textAlign: 'right',
              }}
            >
              <div style={{ flex: 1, margin: 0 }}>
                <button style={{ backgroundColor: '#dc3545', color: '#FFF' }} onClick={deleteItem}>
                  Delete
                </button>
              </div>
            </div>
          </>
        )}
      </Screen>
    </Container>
  );
}

export default withRouter(LineItem);
