import React from 'react';

import TableChart from '@material-ui/icons/TableChart';
import LineItemIcon from '@material-ui/icons/MenuOpen';
import SummaryScreen from '../screens/Summary';
import LineItemsScreen from '../screens/LineItems';
import SingleItemScreen from '../screens/LineItem';

const config = {
  navigation: {
    items: [
      {
        id: 'summary',
        title: 'Summary',
        path: '/summary',
        icon: <TableChart fontSize='large' style={{ color: 'white' }} />,
      },
      {
        id: 'line_items',
        title: 'Line Items',
        path: '/items',
        icon: <LineItemIcon fontSize='large' style={{ color: 'white' }} />,
      },
    ],
  },
  routes: [
    {
      path: '/summary',
      main: () => <SummaryScreen />,
    },
    {
      path: '/items/:id',
      main: () => <SingleItemScreen />,
    },
    {
      path: '/items',
      main: () => <LineItemsScreen />,
    },
  ],
  defaultPath: '/summary',
};

export default config;
