import styled from 'styled-components';

const Screen = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export default Screen;
